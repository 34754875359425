/*eslint-disable */
import Fuse from "fuse.js";
import axios from 'axios';
import config from "configApp";
import _, { fromPairs, groupBy, isPlainObject, sortBy, toPairs } from "lodash";
import moment from "moment";

export function getLocalStorage(name, stringify = true) {
  return stringify
    ? JSON.parse(window.localStorage.getItem(name))
    : window.localStorage.getItem(name);
}

export async function setLocalStorage(name, value, stringify = true) {
  await window.localStorage.setItem(
    name,
    stringify ? JSON.stringify(value) : value
  );
}

export async function removeLocalStorage(name) {
  await window.localStorage.removeItem(name);
}

export function groupByAlphabet(data, keyName = "name") {
  let groups = groupBy(data, elem => {
    if (isPlainObject(elem)) {
      return elem[keyName].toLowerCase().substring(0, 1);
    } else {
      return elem.toLowerCase().substring(0, 1);
    }
  });
  groups = fromPairs(sortBy(toPairs(groups), 0));
  Object.keys(groups).forEach(key => {
    groups[key] = sortBy(groups[key], elem => {
      if (isPlainObject(elem)) {
        return elem[keyName];
      } else {
        return elem;
      }
    });
  });
  return groups;
}
export function searchByText(data, keys, text) {
  let options = {
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: keys
  };
  let fuse = new Fuse(data, options);
  return fuse.search(text);
}
export function searchReseller(data1, data2, data3) {
  let res = [];
  let listClient = [];
  let listRecommendation = [];
  for (var i = 0; i < data1.length; i++) {
    listClient = searchByText(data2, ["reseller"], data1[i].idManageReseller);

  }
  return uniq(listClient);
}

export function searchClient(data1, data2) {
  let res = [];
  let listRecommendation = [];
  for (var i = 0; i < data1.length; i++) {
    listRecommendation = searchByText(data2, ["client"], data1[i].idClient);
    if (listRecommendation.length > 0) {
      for (var k = 0; k < listRecommendation.length; k++) {
        if (
          listRecommendation[k].status === "Confirmed" ||
          listRecommendation[k].idRecommendation === "new"
        ) {
          res.push(data1[i]);
        }
      }
    }
  }
  return uniq(res);
}

export function uniq(a) {
  return a.sort().filter(function(item, pos, ary) {
    return !pos || item != ary[pos - 1];
  });
}
export function searchItem(data, key, text) {
  let res = _.find(data, function(o) {
    return o[key] === text;
  });
  if (res) {
    res = res.name;
  }
  return res;
}

export function searchData(data, text) {
  let rs = _.find(data, function(o) {
    return o.element.productName === text;
  });
  return rs;
}

export function searchPackaging(data, text) {
  let keys = ["mixType"];
  let options = {
    threshold: 0.0,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: keys
  };
  let fuse = new Fuse(data, options);

  if (fuse.search(text).length === 0) {
    return data.unique();
  } else {
    return fuse.search(text);
  }
}

Array.prototype.unique = function() {
  var r = new Array();
  o: for (var i = 0, n = this.length; i < n; i++) {
    for (var x = 0, y = r.length; x < y; x++) {
      if (r[x].label == this[i].label) {
        continue o;
      }
    }
    r[r.length] = this[i];
  }
  return r;
};
export function formatTime(data) {
  // change mm/dd/yy
  data = data.split("/");
  data = `${data[1]}/${data[0]}/${data[2]}`;

  let time = new Date(`${data}`);

  let res = time.toLocaleString("en-us", {
    day: "numeric",
    month: "long",
    year: "numeric"
  });
  res = res.split(" ");

  return `${res[1].split(",")[0]} ${res[0]} ${res[2]}`;
}

export function checkValidDateFormat(str) {
  let date = new Date(str).toString();
  return date !== "" && date !== "Invalid Date";
  // var regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm;
  // return regex.test(str);
}

export function formatTimeHome(data) {
  
  let res = data;
  if (data !== "") {
    data = data.split(", ");
    let dateString = data[0];
    let timeString = data[1];

    // Determine which was valid date
    if (!checkValidDateFormat(data[0])) {
      dateString = data[1];
      timeString = data[0];
    }

    // dd/mm/yy
    let time = new Date(`${dateString}`);
    time = time.toLocaleString("en-us", {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
    time = time.split(" ");

    let day = `${time[1].split(",")[0]} ${time[0]} ${time[2]}`;

    let temp = timeString.split(" ");
    let hours = timeString.split(":");
    hours = `${hours[0]}:${hours[1]} ${temp[1]}`;

    res = `${day}, ${hours}`;
  }
  return res;
}

export function checkExist(data, text) {
  let res = "";
  if (
    _.find(data, function(o) {
      return o.product_1 === text;
    })
  ) {
    res = _.find(data, function(o) {
      return o.product_1 === text;
    });
  } else if (
    _.find(data, function(o) {
      return o.product_2 === text;
    })
  ) {
    res = _.find(data, function(o) {
      return o.product_2 === text;
    });
  }
  return res;
}

export function arrayRemove(array, key, text) {
  var res = _.remove(array, function(n) {
    return n[key] != text;
  });
  return res;
}

export function searchAutoAdd(data, text) {
  return data.filter(o => {
    return o.autoAdd == "Yes" && o.mixType.indexOf(text) >= 0;
  })
  console.log(data);
  console.log(text);
  let optionsMixtype = {
    threshold: 0,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["mixType"]
  };
  let optionsAutoAdd = {
    threshold: 0,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["autoAdd"]
  };
  let arr1 = new Fuse(data, optionsMixtype);
  console.log(arr1);
  let res1 = arr1.search(text);
  console.log(res1);

  let res2 = [];
  if (text != "All") {
    let arr2 = new Fuse(data, optionsMixtype);
    res2 = arr2.search("All");
  }
  console.log(res2);

  let res3 = res1.concat(res2);
  console.log(res3);

  let resData = new Fuse(res3, optionsAutoAdd);
  console.log(resData);
  return resData.search("Yes");
}

export function sortOn(property) {
  return function(a, b) {
    if (a[property] < b[property]) {
      return -1;
    } else if (a[property] > b[property]) {
      return 1;
    } else {
      return 0;
    }
  };
}
function compare_item(a, b) {
  // a should come before b in the sorted order
  if (a.item < b.item) {
    return -1;
    // a should come after b in the sorted order
  } else if (a.item > b.item) {
    return 1;
    // and and b are the same
  } else {
    return 0;
  }
}

export function searchElement(data, category) {
  return _.sortBy(
    _.filter([...data], function(item) {
    return item["category"].indexOf(category==='All'?'':category) > -1;
  }), 'element');
}
export function searchProduct(data, text1, text2, text3 = "") {
  console.log("searchProduct---------", text1, text2, text3);
  text1 = text1.replace("%20", " ");
  text2 = text2.replace("%20", " ");
  text3 = text3.replace("%20", " ");
  // text1 is category
  // text2 is mixType
  // text3 is element
  let filteredCategory = [...data];

  filteredCategory = _.filter(filteredCategory, function(item) {
    return item.autoAdd && item.autoAdd.indexOf("No") > -1;
  });

  filteredCategory = _.filter(filteredCategory, function(item) {
    return item.category && item.category.indexOf(text1) > -1;
  });

  filteredCategory = _.filter(filteredCategory, function(item) {
    return item.mixType && item.mixType.indexOf(text2) > -1;
  });

  filteredCategory = _.filter(filteredCategory, function(item) {
    return item.element && item.element.indexOf(text3) > -1;
  });
  // console.log("filteredCategory-----not sort----", filteredCategory);
  filteredCategory = _.sortBy(filteredCategory, ["productName"]);
  //console.log("filteredCategory---sort--", filteredCategory);
  return filteredCategory;

  // let optionsCategory = {
  //   // threshold: 0,
  //   // location: 0,
  //   // distance: 100,
  //   // maxPatternLength: 32,
  //   // minMatchCharLength: 1,
  //   keys: ["category"]
  // };
  // let optionsMixtype = {
  //   // threshold: 0,
  //   // location: 0,
  //   // distance: 100,
  //   // maxPatternLength: 32,
  //   // minMatchCharLength: 1,
  //   keys: ["mixType"]
  // };
  // let optionsAutoAdd = {
  //   threshold: 0,
  //   location: 0,
  //   distance: 100,
  //   maxPatternLength: 32,
  //   minMatchCharLength: 1,
  //   keys: ["autoAdd"]
  // };
  //
  // let optionElement = {
  //   keys: ["element"]
  // };
  //
  // let arr1 = new Fuse(data, optionsCategory);
  // let arr2 = new Fuse(arr1.search(text1), optionsMixtype);
  // // console.log("arr2----------------", arr2);
  // let res1 = arr2.search(text2);
  // // console.log("res1----------------", res1);
  // let res2 = [];
  // //  5c1a7c1017476f49f4e76eec mix type = Liquid
  // if (text2 != "5c1a7c1017476f49f4e76eec") {
  //   let arr3 = new Fuse(data, optionsCategory);
  //   let arr4 = new Fuse(arr3.search(text1), optionsMixtype);
  //
  //   res2 = arr4.search("");
  // }
  // // console.log("res2----------------", res2);
  // let res3 = res1.concat(res2);
  // console.log("res3----------------", res3);
  // let resData = new Fuse(res3, optionsAutoAdd);
  // if (text3) {
  //   let arr3 = new Fuse(resData.search(text3), optionElement);
  //   console.log("arr3----------------", arr3);
  //   return resData.search("No").sort(sortOn("productName"));
  // } else {
  //   let resultSearch = resData.search('No').sort(sortOn("productName"));
  //   // console.log("resultSearch----------------", JSON.stringify(resultSearch));
  //   return resultSearch;
  // }
}

export function searchByTextSubstr(data, key, text) {
  text = text.toLowerCase().trim();
  let filterData = [];
  for (let obj of data) {
    let str = obj[key].toLowerCase();
    if (str.includes(text)) {
      filterData.push(obj);
    }
  }
  return filterData;
}
export function createUUID() {
  // http://www.ietf.org/rfc/rfc4122.txt
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}
export function numberWithCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function numberWithCommasParser(value) {
  return value.toString().replace(/,/g, "");
}
export function trim(value) {
  if (!value) {
    return value;
  }
  if (typeof value === "string") {
    return value.trim();
  } else if (Array.isArray(value)) {
    return value.map(ele => {
      ele = trim(ele);
      return ele;
    });
  } else if (typeof value === "object") {
    Object.keys(value).forEach((key, index) => {
      value[key] = trim(value[key]);
    });
  }

  return value;
}

export async function checkConnection() {

    return axios.get(config.knack.requestUrl.connectionCheckUrl)
      .then(() => {
          console.log('connection ok');
          return true
        }
      )
      .catch(() => {
          console.log('connection check fail');
          return false
        }
      );
  //return new Promise((resolve, reject) => {
    //resolve(true);
    //resolve(false);
  //})
}
/*export async function checkConnection() {
  return new Promise((resolve, reject) => {
    // Wait for plugin init
    if (typeof Connection === "undefined") {
      document.addEventListener("pluginReady", function() {
        var networkState = navigator.connection.type;

        var states = {};
        states[Connection.UNKNOWN] = "No network connection";
        states[Connection.ETHERNET] = "Ethernet connection";
        states[Connection.WIFI] = "WiFi connection";
        states[Connection.CELL_2G] = "Cell 2G connection";
        states[Connection.CELL_3G] = "Cell 3G connection";
        states[Connection.CELL_4G] = "Cell 4G connection";
        states[Connection.CELL] = "Cell generic connection";
        states[Connection.NONE] = "No network connection";

        if (states[networkState] === "No network connection") {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    } else {
      var networkState = navigator.connection.type;

      var states = {};
      states[Connection.UNKNOWN] = "No network connection";
      states[Connection.ETHERNET] = "Ethernet connection";
      states[Connection.WIFI] = "WiFi connection";
      states[Connection.CELL_2G] = "Cell 2G connection";
      states[Connection.CELL_3G] = "Cell 3G connection";
      states[Connection.CELL_4G] = "Cell 4G connection";
      states[Connection.CELL] = "Cell generic connection";
      states[Connection.NONE] = "No network connection";

      if (states[networkState] === "No network connection") {
        resolve(false);
      } else {
        resolve(true);
      }
    }
  });
}*/

export async function getAuthToken() {
  return new Promise((resolve, reject) => {
    // Mobile device
    if (window.cordva) {
      NativeStorage.getItem(
        "authToken",
        authToken => {
          resolve(authToken);
        },
        err => {
          reject(err);
        }
      );
    } else {
      resolve(window.localStorage.getItem("authToken"));
    }
  });
}
export async function setAuthToken(authToken) {

  window.localStorage.setItem("authToken", authToken);
  
}
export async function getCurrentGeo() {
  return new Promise((resolve, reject) => {
      //Tracking on browser
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve(position);
        },
        err => {
          reject(err);
        }
      );
  });
}

/*
export async function downloadFile(url, targetUrl) {
  return new Promise((resolve, reject) => {
    if (checkTargetPlatform()) {
      //Should works only with Android and iOS
      let fileTransfer = new FileTransfer();
      let uri = encodeURI(url);
      fileTransfer.download(
        uri,
        targetUrl,
        function(entry) {
          resolve(targetUrl);
        },
        function(error) {
          reject(error);
        }
      );
    } else {
      resolve();
    }
  });
}
export async function downloadImageBase64(url) {
  return new Promise((resolve, reject) => {
    if (checkTargetPlatform()) {
      let xhr = new XMLHttpRequest();
      xhr.onload = function() {
        let reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
  });
}*/

/*export function checkTargetPlatform() {
 /* if (window.cordova) {
    return (
      window.cordova.platformId === "ios" ||
      window.cordova.platformId === "android"
    );
  }
  return false;
}*/
/*export function checkPlatform(platform) {
  return false; //todo, refactor/remove code that calls this
  //return window.cordova && window.cordova.platformId === platform;
}
export function getLastSyncedString() {
  const lastSynced = moment(new Date()).format("DD MMM YYYY, hh:mma");
  return lastSynced;
}*/

export const requiredField = (name, value) => {
  if (!value) {
    return `Please Enter ${name}`;
  }
};

export const mergeDataByField = (data, field = "id") => {
  return data
    .map(item => {
      return item[field] || "";
    })
    .join(", ");
};

export function getParam(name) {
  let url = window.location.href;
  url = new URL(`${window.location.origin}?${url.split("?")[1]}`)
  return url.searchParams.get(name);
}
